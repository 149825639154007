<script>
	import FeedbackForm from "./FeedbackForm.svelte";
    import {mini, f_type} from "./Captcha/stores.js";
	export let mini_p = true;
	export let f_type_p = "";
    
	mini.set(mini_p);
	f_type.set(f_type_p);
</script>

<FeedbackForm/>