<script>
  import {b_enable, name, email, phone, SendDataToServer, SaveСoordinatesButton, f_type} from './Captcha/stores.js';
  import Captcha from "./Captcha/Captcha.svelte";
  import { onMount } from 'svelte';
  // import { imask, MaskedInput } from 'svelte-imask@1.2.0'; 
  let outerWidth;
  let className, className2;
  let options = { 
		mask: '+{375}(00)000-00-00' 
	};

	onMount(async () => {
		if (outerWidth < 1000) {
      className = "col-12";
      className2 = "btn btn-outline-danger col-12";
    }else {
      className = "col-2";
      className2 = "btn btn-outline-danger col-2";
    }
	});

  async function onmousemove_checkbox(event) {
		let button_input = document.getElementById("button_input"+$f_type);
		if (!button_input.disabled){
			let rect = event.target.getBoundingClientRect();
      		let x = event.clientX - rect.left; 
      		let y = event.clientY - rect.top;  
			SaveСoordinatesButton(x+':'+y);
		}
	}

  async function onSend(event) {
    SendDataToServer();
  };    
</script>

<svelte:window bind:outerWidth={outerWidth}/>

<style>
  .row p {
    font-size: 18px;
    color: white;
  }
  .row {
    width:100%;
  }
  .btn-outline-danger {
    color: red;
    font-weight: bold;
    background-color: yellow;
  }

  input:invalid {
    background-color:  pink;
  }

  input:valid {
    background-color:  white;
  }
</style>

<div class="row">
      <p class="col-form-label">Оставьте заявку прямо сейчас</p>
</div>
<div class="row" style="margin: 0 auto;" >
    <div class={className}>
      <input type=text bind:value={$name} placeholder="Ваше имя" class="form-control" required>
    </div>
    <div class={className}>
      <input type=email bind:value={$email} placeholder="Ваше email" class="form-control" required>
    </div>
    <div class={className} style="float: left;">
      <input  name="phone" type="tel" bind:value={$phone} placeholder="Ваше телефон" class="form-control" required>
    </div>
    <div class={className} style="float: left;">
      <Captcha/>
    </div>
    <input id="button_input{$f_type}" style="margin:0;" class={className2} type="button" 
    value="Отправить" on:mousemove={onmousemove_checkbox} on:click={onSend} disabled='{$b_enable}'> 
</div>


