import App from './App.svelte';

const app2 = new App({
    target: document.querySelector('#feedback_form2'),
    props: {
        f_type_p: '2',
        mini_p: true,
    }
});

export default app2;