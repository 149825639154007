<script>
	import { onMount } from "svelte";
	import { a_name, captcha_param, key, Init_Captcha, Init_Captcha_All, 
    SaveСoordinates, SendСoordinates, b_enable, f_type, mini } from "./stores.js";
    
	let url = document.location.toString();
	
	async function animate(square_box, a, data1, x_max) {
		if (square_box.style.display=="block"){
			if (a.style.marginLeft == "") {
				a.style.marginLeft = '0px';
			}	
			let x = parseInt(a.style.marginLeft.replace('px',''));
			if (x >= (x_max)) {
				data1.n = -data1.n;
			} 
			if (x<0) {
				data1.n = -data1.n;
			}
			a.style.marginLeft = x + data1.n + 'px';
		}
	}

	async function Start_Action(){
		let f = $captcha_param;
	  	let ar  = f.split("-");
	  	let square_box = document.getElementById("square_box");
		let a = square_box.childNodes[0];  
		let x_max  = square_box.clientWidth-parseInt(ar[0])*(a.clientWidth+4);
		let data1  = {n:5};
  		setInterval(function(){animate(square_box, a, data1, x_max);} ,150);	
	}

	let onclik_square = function (event) {
		if (event.srcElement.classList.contains("red_square")){
			event.srcElement.classList.remove("red_square");
		}
	};

 	async function Show_Captcha() {
	  let f = $captcha_param;
	  let ar  = f.split("-");
	  let square_box = document.getElementById("square_box");
	  square_box.innerHTML = '';
	  if (square_box != null){
		for(let i=0; i< parseInt(ar[0]); i++){
			var new_div = document.createElement("div");
			new_div.classList.add("square");
			if (parseInt(ar[1][i])==1){
				new_div.onclick = onclik_square;
				new_div.classList.add("red_square");  
			}
			square_box.append(new_div);
	  	}
	  }	  
  	}

	async function onclick_checkbox(event) {
		let checkbox_input = document.getElementById("checkbox_input"+$f_type);
		if (checkbox_input.style.display == 'none'){
			checkbox_input.checked = true;
			checkbox_input.style.display ='block';
			let res = await SendСoordinates();
			if (!res){
                if (!mini) refresh();
			}
		}
		else {
			checkbox_input.checked = false;
			checkbox_input.style.display = 'none';
			let main_box4 = document.getElementById("main_box4"+$f_type);
		    main_box4.style.display = 'none';
			let square_box = document.getElementById("square_box"+$f_type);
	  		square_box.innerHTML = '';
			square_box.style.display == 'none';
            b_enable.set(true);
		}	
	}

	async function onmousemove_checkbox(event) {
		let checkbox_input = document.getElementById("checkbox_input"+$f_type);
		if (!checkbox_input.checked){
			let rect = event.target.getBoundingClientRect();
      		let x = event.clientX - rect.left; 
      		let y = event.clientY - rect.top;  
			SaveСoordinates(x+':'+y);
		}
	}

	async function refresh(){
		let checkbox_input = document.getElementById("checkbox_input"+$f_type);
		if (checkbox_input.checked && !$mini ) {
			let main_box4 = document.getElementById("main_box4"+$f_type);
		    main_box4.style.display = 'block';
			let square_box = document.getElementById("square_box"+$f_type);
			square_box.innerHTML = '';
			let r = await Init_Captcha_All(); // Инициализируем элемент captcha
			if(r){
				await Show_Captcha(); // Отображаем капчу на форме
				await Start_Action(); // Начинаем движене блоков
            }	
		}	
	}

	async function refresh_all(){
		let checkbox_input = document.getElementById("checkbox_input"+$f_type);
		checkbox_input.style.display = 'none';
		checkbox_input.checked       = false;
		let main_box4 = document.getElementById("main_box4"+$f_type);
		main_box4.style.display = 'none';
		let square_box = document.getElementById("square_box"+$f_type);
		square_box.innerHTML = '';
        Init_Captcha();
		refresh();	
	}

	onMount(async () => {
		await refresh_all();	
	});

	
//   -->WS
//   let ws_scheme = window.location.protocol == "https:" ? "wss" : "ws";
//   let hostname = window.location.hostname;
//   let port = "12001";
//   let host_port = hostname == "127.0.0.1" ? "8000" : port;
//   let str_port = host_port == "" ? "" : ":" + host_port;
//   let url_ws = ws_scheme + "://" + hostname + str_port + "/ws/orders/";

//   let chatSocket = new WebSocket(url_ws);
//   chatSocket.onmessage = function(e) {
//     let data = JSON.parse(e.data);
//     if (data["message"]["refresh_orders"]) {
//       if (data["message"]["refresh_orders"] == "ok") {
//         loadOrdersToStores();
//       }
//     }
//     if (data["message"]["refresh_category_list"]) {
//       if (data["message"]["refresh_category_list"] == "ok") {
//         loadCategoryListToStores();
//       }
//     }
//   };
  
//   function loadCategoryListToStoresWithСounter()
//   {
//      if (к>100) location.reload(true);
//     loadCategoryListToStores();
//   }
  
//   chatSocket.onclose = function(e) {
//     console.log("WebSocket close");
//     let timerOrders = setInterval(() => loadOrdersToStoresWithСounter(), 60000);
//     let timerCategoryList = setInterval(
//       () => loadCategoryListToStoresWithСounter(),
//       360000
//     );
//   };
//   <--WS
</script>

<style>
	input.larger {
		width: 27px;
        height: 27px;
		margin: 0;
		padding: 0;
		float: left;
		display: none;
		background-color: white;
		cursor: pointer;
		-webkit-appearance: none;
		-moz-appearance: none;
		-o-appearance: none;
		border: none;
		appearance: none;
		outline: none;
		transition-duration: 0.3s;
	}

	input.larger:checked + p::before {
		width: 0;
		height: 0;
		background-color: white;
		content: '\2713';
    	text-align: center;
    	color: red;
		display: block;
		font-size: 20px;
		font-weight: bold;
		position: relative;
    	left: -21px;
    	top: 0;
    }
	#checkbox_block {
        background-color: white;
		width: 29px;
        height: 29px;
		margin: 0;
		padding: 0;
		float: left;
		border-radius: 2px;
		border: 1px solid #000;
		display: block;
		cursor: pointer;
	}

    #checkbox_block1 {
        background-color: white;
		width: 29px;
        height: 29px;
		margin: 0;
		padding: 0;
		float: left;
		border-radius: 2px;
		border: 1px solid #000;
		display: block;
		cursor: pointer;
	}

    #checkbox_block2 {
        background-color: white;
		width: 29px;
        height: 29px;
		margin: 0;
		padding: 0;
		float: left;
		border-radius: 2px;
		border: 1px solid #000;
		display: block;
		cursor: pointer;
	}

	#square_box {
		width: 360px;
        height: 40px;
		border: 1px solid royalblue;
		margin: 1px;
		position: fixed;
		display:block;	
	}
    #square_box1 {
		width: 360px;
        height: 40px;
		border: 1px solid royalblue;
		margin: 1px;
		position: fixed;
		display:block;	
	}
    #square_box2 {
		width: 360px;
        height: 40px;
		border: 1px solid royalblue;
		margin: 1px;
		position: fixed;
		display:block;	
	}
	.main_box2 {
		width: auto;
		height: 32px;
		margin: 1px;
		vertical-align: middle;
	}
	.main_box3 {
		width: 362px;
		height: 25px;
		margin: 1px;	
	}
	.main_box3>p {
		width: 240px;
		font-size: 15px;
		margin: 0 1px;
		padding: 0;
		display: flex;
		float: left;
	}
	.main_box2>p {
		width: 130px;
		height: 30px;
		padding-top: 5px;
	}
	button {
		width: 120px;
		height: 23px;
		font-size: 15px;
		margin: 1px 0;
		float: right;
		padding: 0;
	}
	:global(.square)  {
		width: 8%;
        height: 90%;
		border: 1px dotted royalblue;
		background-color: white;	
		float: left;
		margin: 0.25%;
		position: relative;
	}

	:global(.red_square) {
		background-color: red;
	}

	@keyframes rotate {
		from {transform: rotate(0deg)}
		to {transform: rotate(360deg)}
	}
	:global(.refresh-start) {
		animation-name: rotate;
		animation-duration: 1s;
		animation-iteration-count: infinite;
		animation-timing-function: linear;
		animation-play-state: running;
	}
    .main_box2 p {
        color: #fff;
        font-weight: bold; 
    }
</style>

<div class="main_box{$f_type}">
	<div class="main_box2">
        <div id="checkbox_block{$f_type}" on:click={onclick_checkbox} on:mousemove={onmousemove_checkbox}>
            <input id="checkbox_input{$f_type}" type="checkbox" class="larger"><p></p>
        </div>
        <p>{$a_name}</p>
	</div>
	<div id="main_box4{$f_type}" style="display:none;"> 
        <div class="main_box3"> 
                <p style="font-style: italic;">Кликните по красным облостям</p>
                <button on:click={refresh}><i class="icon-spin3 animate-spin"></i>Обновить</button>
        </div>
        <div id="square_box{$f_type}"></div>
	</div>		
</div>
