import { writable, derived } from 'svelte/store';
import Swal from "sweetalert2/dist/sweetalert2.js";

export let captcha_param = writable([]);
export let a_name = writable("Я не робот");
export let key = writable("");
export let f_type = writable("");
export let b_enable = writable(true);
export let mini = writable(true);
export let name = writable("");
export let email = writable("");
export let phone = writable("");

let Сoordinates = writable([]);
let СoordinatesButton = writable([]);

let guid = () => {
    let s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export async function Init_Captcha() {
    let key_ = guid();
    key.set(key_);
}

export async function Init_Captcha_All() {
    let key_ = [];
    let unsubscribe = key.subscribe(value => {
        key_ = value;
    });
    let url = "/init_captcha/?key=" + key_;
    let captcha_param_ = [];

    // let json = { "ok": true, "data": "9-101011011-500" }
    // captcha_param_ = json["data"];
    // captcha_param.set(captcha_param_);

    let response = await fetch(url);
    if (response.status == 200) {
        if (response.ok) {
            let json = await response.json();
            //let json = {"ok": true, "data": "9-001001100-500"}
            captcha_param_ = json["data"];
            captcha_param.set(captcha_param_);
            return json["result"];
        }
    } else if (response.status == 404) {
        console.log("Сервер не доступен. Повторите попытку позже.");
        return false;
    }
    return captcha_param;
}

export async function SaveСoordinates(prm) {
    let coordinates_ = [];
    let unsubscribe = Сoordinates.subscribe(value => {
        coordinates_ = value;
    });
    let l_ar = coordinates_.length;
    if (l_ar > 3) {
        coordinates_ = coordinates_.slice(l_ar - 3);
    }
    coordinates_.push(prm);
    Сoordinates.set(coordinates_);
}

export async function SendСoordinates() {
    let coordinates_2 = [];
    let unsubscribe = Сoordinates.subscribe(value => {
        coordinates_2 = value;
    });
    let l_ar = coordinates_2.length;
    if (l_ar > 3) {
        coordinates_2 = coordinates_2.slice(l_ar - 3);
        // Отпровляем координаты для валидации на сервер
        let key_ = "";
        let unsubscribe2 = key.subscribe(value => {
            key_ = value;
        });

        let data = { "key": key_, "data": coordinates_2 };
        let url = "";
        let response = await fetch("/send_captcha/", {
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (response.status == 200) {
            if (response.ok) {
                let json = await response.json();
                b_enable.set(!json["result"]);
                return json["result"];
            } else {
                return false;
            }
        } else if (response.status == 404) {
            console.log("Сервер не доступен. Повторите попытку позже.");
            return false;
        }
    }
    return false;
}

export async function SaveСoordinatesButton(prm) {
    let coordinates_ = [];
    let unsubscribe = СoordinatesButton.subscribe(value => {
        coordinates_ = value;
    });
    let l_ar = coordinates_.length;
    if (l_ar > 3) {
        coordinates_ = coordinates_.slice(l_ar - 3);
    }
    coordinates_.push(prm);
    СoordinatesButton.set(coordinates_);
}

export async function clear_field() {
    Init_Captcha();

    let f_type_ = [];
    let unsubscribe = f_type.subscribe(value => {
        f_type_ = value;
    });

    let checkbox_input = document.getElementById("checkbox_input" + f_type_);
    checkbox_input.checked = false;
    checkbox_input.style.display = 'none';

    b_enable.set(false);
    name.set("");
    email.set("");
    phone.set("");
}

export async function SendDataToServer() {
    let coordinates_ = [];
    let unsubscribe = СoordinatesButton.subscribe(value => {
        coordinates_ = value;
    });
    let l_ar = coordinates_.length;
    if (l_ar > 3) {
        coordinates_ = coordinates_.slice(l_ar - 3);
        // Отпровляем координаты для валидации на сервер
        let key_ = "";
        let unsubscribe2 = key.subscribe(value => {
            key_ = value;
        });

        let name_ = "";
        let unsubscribe_name = name.subscribe(value => {
            name_ = value;
        });

        let email_ = "";
        let unsubscribe_email = email.subscribe(value => {
            email_ = value;
        });

        let phone_ = "";
        let unsubscribe_phone = phone.subscribe(value => {
            phone_ = value;
        });

        let data2 = { "coordinates": coordinates_, "name": name_, "phone": phone_, "email": email_ }
        let data = { "key": key_, "data": data2 };
        let url = "";
        let response = await fetch("/feedback_form/", {
            method: 'POST',
            body: JSON.stringify(data),
        });

        if (response.status == 200) {
            if (response.ok) {
                let json = await response.json();
                b_enable.set(!json["result"]);
                if (json["result"]) {
                    clear_field();
                    Swal.fire({
                        title: "Success",
                        text: "Спасибо, с Вами скоро свяжутся!",
                        buttonsStyling: false,
                        confirmButtonClass: "btn btn-success",
                        icon: "success"
                    });
                } else {
                    clear_field();
                    Swal.fire({
                        title: "Warning",
                        text: "В результате отправки данных произошла ошибка, попробуйте попытку позже",
                        buttonsStyling: false,
                        confirmButtonClass: "btn btn-warning",
                        icon: "warning"
                    })
                }
                return json["result"];
            } else {
                return false;
            }
        } else if (response.status == 404) {
            console.log("Сервер не доступен. Повторите попытку позже.");
            return false;
        }
    }
    return false;
}